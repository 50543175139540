import { HeartIcon as FavoritesIcon } from "@heroicons/react/24/outline";
import Link from "next/link";

import Logo from "@/src/assets/logo.png";
import Image from "next/image";
import { ISearch, IMenuItem } from "@/src/lib";
import Search from "../Search/NavbarSearch/Search";
import { LanguageSwitch } from "../LanguageSwitch";
import { DesktopMenu } from "./Desktop";
import { MobileMenu } from "./Mobile";

interface Props {
  menuItems: IMenuItem[];
  lng: string;
  searchWords: ISearch;
}

export const Navbar: React.FC<Props> = ({ menuItems, lng, searchWords }) => {
  return (
    <nav className="bg-white shadow pt-6">
      <div className="h-6 flex justify-between items-center px-4 sm:px-6 lg:px-8 max-w-[1920px]l mx-auto">
        <Link href={`/${lng}`}>
          <Image unoptimized src={Logo} alt="srpske knjige" />
        </Link>
        <div className="flex gap-2 items-center relative">
          {/* <SearchIcon width={28} height={28} /> */}
          <Search lng={lng} words={searchWords} />
          <Link href={`/${lng}/omiljene`}>
            <span className="sr-only">search</span>
            <FavoritesIcon color="#F4AD22" width={28} height={28} />
          </Link>
          <div className="hidden sm:flex">
            <LanguageSwitch lng={lng} />
          </div>
        </div>
      </div>
      <DesktopMenu lng={lng} menuItems={menuItems} />
      {/* Mobile nav */}
      <MobileMenu lng={lng} menuItems={menuItems} />
    </nav>
  );
};
