// Docs: https://aws.amazon.com/developer/application-security-performance/articles/image-optimization
export default function cloudfrontLoader({ src, width, quality }: any) {
  const url =
    process.env.NEXT_PUBLIC_BE_ENV === "local"
      ? new URL(`http://localhost:1337${src}`)
      : new URL(`${src}`);
  url.searchParams.set("format", "auto");
  url.searchParams.set("width", width.toString());
  url.searchParams.set("quality", (quality || 75).toString());
  return url.href;
}
