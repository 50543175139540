import { IMenuItem } from "@/src/lib";
import { DesktopMenuItem } from "./DesktopMenuItem";

interface Props {
  menuItems: IMenuItem[];
  lng: string;
}

export const DesktopMenu: React.FC<Props> = ({ menuItems, lng }) => {
  return (
    <div className="mx-auto max-w-[1920px] hidden sm:block">
      <div className="relative flex h-16 justify-between">
        <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-center">
          <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
            {menuItems.map((menuItem) => (
              <DesktopMenuItem
                key={menuItem.id}
                menuItem={menuItem}
                lng={lng}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
