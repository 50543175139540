"use client";
import { useEffect, useRef, useState } from "react";
import { useDebounceValue } from "usehooks-ts";
import { MagnifyingGlassIcon as SearchIcon } from "@heroicons/react/24/outline";
import { ISearch } from "@/src/lib/navbar/search/types";

interface Props {
  words: ISearch;
  onChange: (s: string) => void;
}

export const PageSearch: React.FC<Props> = ({ onChange, words }) => {
  const { placeholder } = words;

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedValue] = useDebounceValue(searchTerm, 600);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue, onChange]);

  const setFocusOnInput = (): void => {
    inputRef.current?.focus();
  };

  return (
    <div className="flex justify-between gap-2 w-full relative overflow-hidden border rounded-lg sm:max-w-2xl">
      <input
        ref={inputRef}
        type="text"
        placeholder={placeholder}
        className="block w-full  text-secondary py-2 px-4 placeholder:text-gray-400 sm:text-sm sm:leading-6 focus:outline-none"
        value={searchTerm}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchTerm(e.target.value)
        }
      />
      <button className="absolute top-0 right-0 bottom-0 px-4 py-1 bg-primary">
        <span className="sr-only">search</span>
        <SearchIcon
          onClick={setFocusOnInput}
          width={24}
          height={24}
          className="text-white"
        />
      </button>
    </div>
  );
};
