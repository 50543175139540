import { ICmsClient } from "../client";
import { CmsQuery } from "../query";
import { PagedResult } from "../paged.result";

export interface StrapiConfig {
    strapiKey: string;
    strapiBaseUrl: string;
}

/**
 * Strapi client is a wrapper around strapi REST logic.
 */
export class StrapiClient implements ICmsClient {

    private readonly strapiKey: string | undefined;
    private readonly strapiBaseUrl: string | undefined;

    private constructor(config: StrapiConfig) {
        this.strapiKey = config.strapiKey;
        this.strapiBaseUrl = config.strapiBaseUrl;
    }

    static create(config: StrapiConfig) {
        return new StrapiClient(config);
    }

    private getStrapiMediaUrl(path: string = ""): string {
        return `${this.strapiBaseUrl}${path}`;
    }

    private buildUrl(path: string, query?: CmsQuery): string {
        const encodeNestedQuery = (obj: Record<string, any>, prefix = ""): string => {
            return Object.entries(obj)
                .map(([key, value]) => {
                    const encodedKey = prefix ? `${prefix}[${encodeURIComponent(key)}]` : encodeURIComponent(key);
                    if (value === undefined || value === null) {
                        return "";
                    } else if (typeof value === "object" && !Array.isArray(value)) {
                        return encodeNestedQuery(value, encodedKey);
                    } else if (Array.isArray(value)) {
                        return value
                            .map((v) => `${encodedKey}=${encodeURIComponent(v)}`)
                            .join("&");
                    } else {
                        return `${encodedKey}=${encodeURIComponent(value)}`;
                    }
                })
                .filter(Boolean)
                .join("&");
        };
    
        let queryString = query ? encodeNestedQuery(query) : "";
        return `${this.strapiBaseUrl}/api${path}${queryString ? `?${queryString}` : ""}`;
    }

    private async fetchContent(path: string, query?: CmsQuery, options: any = {}) {
        if(!query?.filters) delete query?.filters;

        const requestOptions = this.buildOptions(options);
        const requestUrl = this.buildUrl(path, query);
        const response: Response = await fetch(requestUrl, requestOptions);
        if (!response.ok) {
            console.log("Error in getting content", await response.json());
            throw new Error(`An error occurred please try again`);
        }

        const {data, meta} = await response.json();
        return {data, pagination: meta.pagination};
    }

    private async manageContent(path: string, payload: any, options: any = {}) {
        const requestOptions = this.buildOptions({...options, body: JSON.stringify({data: payload})});
        const requestUrl = this.buildUrl(path);

        const response: Response = await fetch(requestUrl, requestOptions);

        if (!response.ok) {
            console.log("Error in managing content", await response.json());
            throw new Error(`An error occurred please try again`);
        }

        const {data} = await response.json();
        return data;
    }

    private buildOptions(options = {}) {
        return {
            next: { revalidate: 10 },
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${this.strapiKey}`
            },
            ...options,
        };
    }

    getMediaUrl(url: string) {
        return url.startsWith("/") ? this.getStrapiMediaUrl(url) : url;
    }

    async fetchContentEntries<T>(path: string, query?: CmsQuery, options: any = {}): Promise<PagedResult<T>> {
        const {data, pagination} = await this.fetchContent(path, query, options);
        const entries = data.map((entry: any) => entry);
        return {entries, pagination};
    }

    async fetchContentEntry<T>(path: string, query?: CmsQuery, options: any = {}): Promise<T> {
        const {data} = await this.fetchContent(path, query, options);
        const entry = Array.isArray(data) ? data[0] : data;
        return entry;
    }

    async createContentEntry<T>(path: string, payload: any, options: any = {}): Promise<T> {
        return await this.manageContent(path, payload, {...(options || {}), method: 'POST'});
    }

    async updateContentEntry<T>(path: string, payload: any = {}, options: any = {}): Promise<T> {
        return await this.manageContent(path, payload, {...(options || {}), method: 'PUT'});
    }

    async deleteContentEntry(path: string, options: any = {}): Promise<any> {
        return await this.manageContent(path, null, {...(options || {}), method: 'DELETE'});
    }
}




