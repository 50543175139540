import { FC } from "react";

interface Props {
  lng: string;
}

export const Footer: FC<Props> = ({ lng }) => {
  return (
    <div className="text-center py-7 bg-white text-[#222222] text-sm">
      &copy; Copyright 2023 Srpske knjige All Rights Reserved.
    </div>
  );
};
