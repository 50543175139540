import { Cms } from "./cms/cms";
import { StrapiClient } from "./cms/strapi/client";

export const extractPath = (input: string): { path: string } => {
    const lastIndex = input.lastIndexOf('/');
    const firstIndex = input.indexOf('/');
    const notHomePath = lastIndex !== -1 && firstIndex !== lastIndex

    return notHomePath ? { path: input.slice(lastIndex) } : { path: "" }
};

export const getStrapiMediaUrl = (path: string = ""): string => {
    const client = Cms.createClient();
    return client.getMediaUrl(path);
}

export const changeLanguageUrl = ({lng, pathname}: { lng: string, pathname: string }): string => {
    const parts = pathname.split('/');
    parts[1] = lng;
    return parts.join('/');
}
