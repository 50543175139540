"use client";
import { IBook } from "@/src/lib/book/types";
import { type FC } from "react";
import dynamic from "next/dynamic";
import { IHashtag } from "@/src/lib/hashtag/types";
import Link from "next/link";
import Image from "next/image";
import clsx from "clsx";

const Slider = dynamic(() => import("../content").then((mod) => mod.Slider), {
  ssr: false,
});

type Props = {
  book: IBook;
  lng: string;
  storyView?: boolean;
};

export const BookPageHeader: FC<Props> = ({ book, lng, storyView = false }) => {
  let hashtags: IHashtag[] = [];

  if (!storyView) {
    if (book.hashtagStrong?.length) {
      hashtags = [...hashtags, ...book.hashtagStrong];
    }
    if (book.hashtagMedium?.length) {
      hashtags = [...hashtags, ...book.hashtagMedium];
    }
    if (book.hashtagRarely?.length) {
      hashtags = [...hashtags, ...book.hashtagRarely];
    }

    hashtags = Array.from(
      new Map(
        hashtags.map((hashtag) => [JSON.stringify(hashtag), hashtag])
      ).values()
    );
  }

  return (
    <div
      className={clsx("px-2 mb-10", storyView ? "h-[520px]" : "")}
      style={{ backgroundColor: book.backgroundColor }}
    >
      <div>
        <div className="pt-6 pb-4 flex flex-col justify-center items-center text-left">
          <h1
            className="text-2xl font-medium"
            style={{ color: book.textColor }}
          >
            {book.title}
          </h1>
          {book.authors.length >= 1 && (
            <h2 className="font-light" style={{ color: book.textColor }}>
              {book.authors[0].name}
            </h2>
          )}
        </div>
        {book.mainImage?.length &&
          (storyView ? (
            <div className="relative flex flex-col mx-auto items-center justify-center h-full max-h-[280px] aspect-[312/451]">
              <Image
                src={book.mainImage[0].url}
                alt={book.mainImage[0].alternativeText}
                fill
              />
            </div>
          ) : (
            <div className="px-20 py-4">
              <Slider sliderItems={book.mainImage} name="shared.slider" />
            </div>
          ))}
      </div>
      {!storyView && (
        <>
          {book.description && (
            <div
              className="px-2 py-4"
              dangerouslySetInnerHTML={{ __html: book.description }}
            ></div>
          )}
          {!!hashtags.length && (
            <div className="px-2 pt-4 py-8 flex gap-1">
              {hashtags.map((hashtag) => (
                <Link
                  href={`/${lng}/hashtag/${hashtag.hashtagSlug}`}
                  key={hashtag.id}
                >
                  {hashtag.name}
                </Link>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};
