"use client";
import { Swiper, SwiperSlide } from "swiper/react";
import type { Swiper as SwiperRef } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Mousewheel, Controller } from "swiper/modules";
import { type FC, useState } from "react";
import Image from "next/image";
import type { TRow } from "@/src/lib";

interface Props {
  row: TRow[];
  lng: string;
}

export const HomeMobileSwiper: FC<Props> = ({ row }) => {
  const [firstSwiper, setFirstSwiper] = useState<SwiperRef | null>(null);
  const [secondSwiper, setSecondSwiper] = useState<SwiperRef | null>(null);

  return (
    <div className="w-full lg:hidden h-full flex flex-col">
      <Swiper
        direction="horizontal"
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        className="w-full flex-1"
        modules={[Controller, Mousewheel]}
        onSwiper={setFirstSwiper}
        controller={{ control: secondSwiper }}
        mousewheel={{ enabled: true, releaseOnEdges: true }}
      >
        {row.map(({ book }) => {
          return (
            <SwiperSlide key={book.id}>
              <div
                style={{
                  color: book.textColor,
                  backgroundColor: book.backgroundColor,
                }}
                className="flex-1 relative flex flex-col items-center justify-center h-full"
              >
                <h1 className="w-2/5 text-lg font-bold">{book.title}</h1>
                {book.authors?.length >= 1 && <h4 className="w-2/5 text-sm">{book.authors[0].name}</h4>}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      <Swiper
        direction="horizontal"
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        modules={[Controller, Mousewheel]}
        onSwiper={setSecondSwiper}
        style={{ transform: "rotate(180deg)" }}
        controller={{ control: firstSwiper }}
        mousewheel={{ enabled: true, releaseOnEdges: true }}
        className="mySwiper w-full h-80 flex-1"
      >
        {row.map(({ story, book }) => {
          return (
            <SwiperSlide key={story.id + book.id} className="relative">
              <div className="flex-1 aspect-[720/377] rotate-180 h-full w-full relative">
                <div
                  style={{ color: story.textColor }}
                  className="absolute top-5 left-5 z-10 text-xl"
                >
                  {story.title} {book.title}
                </div>
                {!!book.mainImage ? (
                  <Image
                    fill
                    src={book.mainImage[0].url}
                    alt={book.mainImage[0].alternativeText}
                    objectFit="cover"
                  />
                ) : (
                  ""
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
