import { ICmsClient } from "./client";
import { StrapiClient } from "./strapi/client";


export class Cms {

    static createClient(): ICmsClient {
        return StrapiClient.create({
            strapiKey: process.env.NEXT_PUBLIC_STRAPI_API_TOKEN || "",
            strapiBaseUrl: process.env.NEXT_PUBLIC_STRAPI_API_URL || "",
        })
    }
}
