interface Props {
  lng: string;
}

export const BookIndicator = ({ lng }: Props) => {
  return (
    <div className="bg-white text-white bg-opacity-30 p-5 flex gap-2 items-center">
      {lng === "sr" ? "KNJIGA" : "КЊИГА"}
      <svg
        width="28"
        height="30"
        viewBox="0 0 28 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3137 28.8703L0.585788 16.1423C-0.19526 15.3613 -0.19526 14.095 0.585788 13.3139L13.3137 0.585995C14.0948 -0.195054 15.3611 -0.195054 16.1421 0.585996C16.9232 1.36705 16.9232 2.63338 16.1421 3.41442L6.82843 12.7281L26 12.7281C27.1046 12.7281 28 13.6236 28 14.7281C28 15.8327 27.1046 16.7281 26 16.7281L6.82843 16.7281L16.1421 26.0418C16.9232 26.8229 16.9232 28.0892 16.1421 28.8703C15.3611 29.6513 14.0948 29.6513 13.3137 28.8703Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
