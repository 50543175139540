import clsx from "clsx";
import type {
  DetailedHTMLProps,
  FC,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  TextareaHTMLAttributes,
} from "react";

type Props = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  inputProps?: DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  label?: string;
  labelProps?: DetailedHTMLProps<
    LabelHTMLAttributes<HTMLLabelElement>,
    HTMLLabelElement
  >;
  textareaProps?: DetailedHTMLProps<
    TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
  >;
};

export const Input: FC<Props> = ({
  label,
  textareaProps,
  labelProps,
  className,
  inputProps,
}) => {
  if (!!textareaProps) {
    return (
      <div className="w-full flex flex-col gap-1 items-start">
        {label && (
          <label
            {...labelProps}
            className={clsx("text-sm", labelProps?.className)}
          >
            {label}
          </label>
        )}
        {
          <textarea
            {...textareaProps}
            className={clsx(
              "w-full text-gray-800 border border-gray-600 rounded p-2 focus:bg-gray-100 outline-none",
              className
            )}
          />
        }
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-1 items-start">
      {label && <label className="text-sm">{label}</label>}
      {
        <input
          {...inputProps}
          className={clsx(
            "w-full text-gray-800 border border-gray-600 rounded p-2 focus:bg-gray-100 outline-none",
            className
          )}
        />
      }
    </div>
  );
};
