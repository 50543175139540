import { ChevronRightIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { FC } from "react";

type Props = {
  order?: "left" | "right";
};

export const SliderArrow: FC<Props> = ({ order = "right" }) => {
  return (
    <div
      className={clsx(
        "group flex justify-center items-center",
        order === "left" && "rotate-180"
      )}
    >
      <ChevronRightIcon
        width={48}
        height={48}
        className="text-[#d9d9d9] opacity-100 transition-all duration-500 group-hover:opacity-25"
      />
      <ChevronRightIcon
        width={48}
        height={48}
        className="text-[#d9d9d9] opacity-50 transition-all duration-500 group-hover:opacity-75 -ml-8"
      />
      <ChevronRightIcon
        width={48}
        height={48}
        className="text-[#d9d9d9] opacity-25 transition-all duration-500 group-hover:opacity-100 -ml-8"
      />
    </div>
  );
};
