import { ChevronRightIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { LanguageSwitch } from "../..";
import { IMenuItem } from "@/src/lib";
interface Props {
  setOpen: (o: boolean) => void;
  menuItems: IMenuItem[];
  lng: string;
}

export const MobileMenuItems: React.FC<Props> = ({ setOpen, menuItems, lng }) => {
  return (
    <div className="space-y-1 flex flex-col items-center px-6 min-w-max">
      {menuItems.map((menuItem) => (
        <Link
          onClick={() => setOpen(false)}
          key={menuItem.id}
          href={
            menuItem.slug !== "pocetna"
              ? `/${lng}/${menuItem.slug || ""}`
              : `/${lng}`
          }
          className="w-full text-white border-b border-b-white flex items-center gap-4 py-2 px-3 text-base font-medium"
        >
          <ChevronRightIcon width={16} height={16} />
          {menuItem.page}
        </Link>
      ))}
      <LanguageSwitch />
    </div>
  );
};
