interface Props {
  lng: string;
}

export const StoryIndicator = ({ lng }: Props) => {
  return (
    <div className="bg-white text-white bg-opacity-30 p-5 flex gap-2 items-center">
      {lng === "sr" ? "PRIČA" : "ПРИЧА"}
      <svg
        width="29"
        height="30"
        viewBox="0 0 29 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.8582 0.585788L27.5861 13.3137C28.3671 14.0948 28.3671 15.3611 27.5861 16.1421L14.8582 28.8701C14.0771 29.6511 12.8108 29.6511 12.0297 28.8701C11.2487 28.089 11.2487 26.8227 12.0297 26.0416L21.3434 16.7279L2.17187 16.7279C1.0673 16.7279 0.171874 15.8325 0.171874 14.7279C0.171874 13.6234 1.0673 12.7279 2.17187 12.7279L21.3434 12.7279L12.0297 3.41421C11.2487 2.63317 11.2487 1.36684 12.0297 0.585788C12.8108 -0.195261 14.0771 -0.195261 14.8582 0.585788Z"
          fill="white"
        />
      </svg>
    </div>
  );
};
