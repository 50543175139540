"use client";

import { FC, ReactNode, useCallback, useEffect, useState } from "react";

interface Props {
  initialText: string;
  searchText: string;
}

export const BookTitle: FC<Props> = ({ initialText, searchText }) => {
  const [text, setText] = useState<ReactNode[] | string>(initialText);
  // const [inputValue, setInputValue] = useState("");

  const changeInput = useCallback(() => {
    let value = searchText.toLocaleLowerCase();
    let idx = initialText.toLocaleLowerCase().indexOf(value);
    if (idx >= 0) {
      let newText = [
        initialText.substring(0, idx),
        <strong key="highlight">
          {initialText.substring(idx, idx + value.length)}
        </strong>,
        initialText.substring(idx + value.length),
      ];
      setText(newText);
    } else {
      setText(initialText);
    }
  }, [initialText, searchText]);

  useEffect(() => {
    searchText && changeInput();
  }, [searchText, initialText, changeInput]);

  return <div className="text-md text-tertiary text-wrap">{text}</div>;
};
