"use client";
import { changeLanguageUrl, extractPath } from "@/src/utils/functions";
import clsx from "clsx";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";

interface Props {
  lng?: string;
}

export const LanguageSwitch: React.FC<Props> = ({ lng }) => {
  const pathname = usePathname();
  const { path } = extractPath(pathname);
  const searchParams = useSearchParams();
  const params = new URLSearchParams(searchParams.toString());

  const isActive = (c: string) => c === lng;

  return (
    <div className="w-full flex justify-center items-center gap-3 pt-20 sm:pt-0 px-6 text-white">
      <Link
        className={clsx(
          isActive("sr-Cyrl") ? "sm:text-primary" : "sm:text-secondary"
        )}
        href={
          path
            ? changeLanguageUrl({
                lng: "sr-Cyrl",
                pathname: pathname + "?" + params.toString(),
              })
            : "/"
        }
      >
        ћир
      </Link>
      <span className="md:text-secondary">|</span>
      <Link
        className={clsx(
          isActive("sr") ? "sm:text-primary" : "sm:text-secondary"
        )}
        href={
          path
            ? changeLanguageUrl({
                lng: "sr",
                pathname: pathname + "?" + params.toString(),
              })
            : "/sr"
        }
      >
        lat
      </Link>
    </div>
  );
};
