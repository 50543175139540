import clsx from "clsx";
import { type FC, type ReactNode } from "react";

interface Props {
  className?: string;
  children: ReactNode;
}

export const PageMain: FC<Props> = ({ className, children }) => {
  return (
    <main
      className={clsx(
        "flex-1 px-4 sm:px-6 lg:px-8 w-full max-w-[1920px] mx-auto",
        className
      )}
    >
      {children}
    </main>
  );
};
