import { BookOpenIcon } from "@heroicons/react/24/outline";

interface Props {
  text?: string;
}

export const NoBooks: React.FC<Props> = ({ text = "Nema knjiga" }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      <BookOpenIcon color="#F4AD22" height={200} width={150} />
      {text}
    </div>
  );
};
