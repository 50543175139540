"use client";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { getBooks } from "./service";
import { IBook } from "./types";
import { CmsQuery, Pagination } from "@/src/utils";

export const useBooks = ({
  filter,
  enabled = true,
}: {
  filter: CmsQuery;
  enabled?: boolean;
}): UseQueryResult<{books: IBook[]; pagination: Pagination}> =>
  useQuery({
    queryKey: ["books", filter],
    queryFn: async () => {
      const { entries, pagination } = await getBooks(filter);
      return { books: entries, pagination };
    },
    enabled: enabled,
  });

export const useSearchBooks = ({
  filter,
  enabled = false,
}: {
  filter: CmsQuery;
  enabled?: boolean;
}): UseQueryResult<IBook[]>=> {
  return useQuery({
    queryKey: ["searchBooks", filter],
    queryFn: async () => {
      const { entries } = await getBooks(filter);
      return entries as IBook[];
    },
    enabled,
  });
};
